.root {
  border: 1px solid rgba(0, 0, 0, .25);
  border-radius: 3px;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 1rem;
  background: rgba(0, 0, 0, .1);

}
