.root {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  > div {
  }
}

.menu {
  order: 1;
  flex: 1 1 25%;
  background-color: rgb(3, 166, 11);
  overflow-y: scroll;
}

.yali {
  order: 2;
  flex: 1 1 50%;
  padding-top: 3em;
  position: relative;
}

.chat {
  order: 3;
  flex: 1 1 25%;
  background-color: rgba(0, 0, 0, 0.1);
  max-height: 100%;
  overflow: scroll;
}
