$font-size: 16px;

.root {
}

:global {
  @import "../../node_modules/normalize.css/normalize.css";

  * {
    box-sizing: border-box;
  }

  body {
    background-color: rgb(177, 252, 206);
    color: rgb(0, 0, 0);
    font-size: $font-size;
    padding: 0;
    font-family: "Comic Sans MS";
  }

  button,
  input {
    padding: 0.5rem;
  }

  [disabled] {
    opacity: 0.5;
  }

  .yali-loading {
    border: 5px dotted rgb(255, 0, 0);
    padding: 1em;
  }
}
