.root {
  margin: 1rem;
  background-color: #B1FCCE;
  padding: 0.5rem;
  display: flex;


  > div {
    align-self: center;
    width: 100%;
  }

}

.isDragging {
  opacity: .5;
}
